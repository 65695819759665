// main: master.scss
/*colors ---------------------------------------------------------------------------------------------------------- colors */;
:root {
    --color-text: #474747;
    --color-text2: #2a3433;
}

$color-text: #000;
$color-base: #3D736B;
$color-alt: #b6b737;
$color-alt2: rgb(228, 228, 228);
$color-alt3: rgb(76, 76, 76);
$color-border: rgb(171, 171, 171);

$color-selection-text: rgba(255, 255, 255, 0.8);
$color-selection-bg: rgba(0, 0, 0, 0.5);
$color-focus: rgba(0, 0, 0, 0.5);

@include selection{background-color:$color-selection-bg;color:$color-selection-text}
@if ($focusInput) {
    @include focusInput{outline: 1px solid $color-focus}
}
/* end.colors */
