// main: master.scss
/*5 smartphones - portrait */ @media (max-width: 480px) { /*-------------------------------------- smartphones - portrait*/
header {
    .header-bottom a.logo {
        max-width: 110px !important;
    }
    img.logo {
        width: 150px;
        margin: auto;
    }
}
footer {
    margin-top: 50px !important;
    .preFooter p {
        width: 100%;
        text-align: center;
    }
    .preFooter a.icon::before {
        margin: auto;
    }
    .footer-navbar .row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
    .footer-navbar .layout-column {
        width: 100%;
        text-align: center;
    }
    a.logo {
        margin: auto;
    }
    #footer_thebricks_line {
        text-align: center;
    }
    .footer-navbar .layout-column:last-child {
        text-align: center;
        margin-bottom: 30px;
    }
    img.logo {
        width: 150px;
        margin: auto;
    }
    .footer-navbar .container {
        padding: 50px 0 0;
    }
}
section.page-content .page-heading,
section.page-content .blockproductscategory .addon-title h3,
section.page-content .addon-title h3,
section.site-content .page-heading,
section.site-content .blockproductscategory .addon-title h3,
section.site-content .addon-title h3 {
    font-size: 35px;
}
.introImg {
    .layout-column.col-lg-12 {
        height: 200px;
        width: 100%;
    }
}
.ponukaDna {
    .product-box {
        margin: 0;
    }
    .owl-theme .owl-controls .owl-nav div.owl-prev {
        left: -20px;
    }
    .owl-theme .owl-controls .owl-nav div.owl-next {
        right: -20px;
    }
    .product-info {
        padding: 30px;
    }
    .buttons {
        display: block;
        text-align: center;
    }
    .product-info .buttons a.link {
        margin-left: 0;
        margin-top: 20px;
        display: block;
    }
}
.productSlider1 {
    .owl-stage {
        pointer-events: auto;
        margin-top: 50px;
    }
    .owl-stage-outer {
        background-image: url(../img/Barriccaia_della_Cantina_Einaudi_transparent.png);
        background-position-y: 30px;
    }
    .owl-item.active {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
        -webkit-transition: 0s ease-in-out;
        transition: 0s ease-in-out;
        width: 0 !important;
    }
    .owl-item.center {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
        width: auto !important;
    }
    img.img-responsive.product-img1 {
        width: 50%;
        margin: 0 25%;
    }
}
.infoPanel {
    margin-top: 0;
    padding: 50px 0;
    h3 {
        font-size: 20px;
    }
    .feature-box {
        margin: 10px 20px;
    }
    .feature-box {
        width: 80px;
    }
}
a.product-btn.cart-button.ajax_add_to_cart_button.btn2 {
    font-size: 16px;
    padding: 5px 10px !important;
}
#product {
    .pb-right-column {
        margin-top: 0;
    }
    .content_prices {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
    .primary_block #price {
        margin-right: 0;
    }
    .weight {
        text-align: right;
    }
    .primary_block .product-description .quantity-box {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
    .quantity-box .option {
        margin-right: 0;
    }
    .primary_block .quantity-box a.link {
        display: none;
    }
    #detailyProduktu .productFeatures {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    #detailyProduktu .productFeatures .item {
        width: 100%;
        margin-right: 0;
    }
    .manufacturerInfo .content {
        margin-top: 0;
    }
    .manufacturerInfo .logo {
        margin-bottom: 0;
    }
    .blockproductscategory .owl-theme .owl-controls .owl-nav div.owl-next {
        right: -20px;
    }
    .blockproductscategory .owl-theme .owl-controls .owl-nav div.owl-prev {
        left: -20px;
    }
}
#login_form_content .btn,
#login_form_content .btn2,
.opc-add-save .btn,
.opc-add-save .btn2 {
    min-width: 90px;
}
#carrier_area {
    .resume td {
        width: 100%;
        display: block;
    }
    .resume td.delivery_option_radio {
        display: table-cell;
        width: auto;
    }
}
#guest-tracking .footer_links,
#guest-tracking .cart_navigation,
#order-confirmation .footer_links,
#order-confirmation .cart_navigation,
#password .footer_links,
#password .cart_navigation,
#module-cashonpickup-validation .footer_links,
#module-cashonpickup-validation .cart_navigation,
#module-cashondeliveryplus-validation .footer_links,
#module-cashondeliveryplus-validation .cart_navigation,
#module-cheque-payment .footer_links,
#module-cheque-payment .cart_navigation,
#module-bankwire-payment .footer_links,
#module-bankwire-payment .cart_navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}
#guest-tracking .exclusive_large,
#order-confirmation .exclusive_large,
#password .exclusive_large,
#module-cashonpickup-validation .exclusive_large,
#module-cashondeliveryplus-validation .exclusive_large,
#module-cheque-payment .exclusive_large,
#module-bankwire-payment .exclusive_large {
    margin-left: 0;
}
#contactSec1 {
    .container {
        padding: 0;
        width: 100%;
    }
    .innerContent a.link {
        font-size: 17px;
    }
}
#contactSec2 {
    .container {
        padding: 0;
        width: 100%;
    }
}
.without-column .product_list .item.ajax_block_product {
    width: 100%;
}
#manufacturers_list li.item {
    width: 100%;
}
#manufacturers_list .mansup-container {
    margin-top: 50px;
}
}/* end.smartphones - portrait*/
