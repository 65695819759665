// main: master.scss
/*3 tablets */ @media (max-width: 1024px) { /*--------------------------------------------------------------------- tablets*/
#address div#HOOK_PAYMENT,
#authentication div#HOOK_PAYMENT,
#order-opc div#HOOK_PAYMENT {
    display: block;
    text-align: center;
    .col {
        display: block;
        p.button-exclusive.btn.btn-default.payment_module {
            margin-bottom: 30px;
            white-space: normal;
        }
        &:last-child {
            p.button-exclusive.btn.btn-default.payment_module {
                margin-bottom: 0;
            }
        }
    }
}
.productSlider1 {
    .owl-stage-outer {
        background-size: 300px;
    }
}
.infoPanel {
    .feature-box {
        width: 90px;
    }
}
#product {
    .primary_block .product-description .quantity-box {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .primary_block .quantity-box a.link {
        position: relative;
        top: 10px;
    }
    .primary_block .quantity-box .option {
        margin-bottom: 10px;
    }
    #detailyProduktu {
        padding-top: 100px;
    }
}

}/* end.tablets*/
