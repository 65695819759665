// main: master.scss
/*function ------------------------------------------------------------------------------------------------------ function */
// .class::after {
//     content: "";
//     width: 1px;
//     background-color: rgba(255, 255, 255, 0.4);
//     height: 100%;
//     position: absolute;
//     right: 13px;
//     top: 0;
// }
// .class:last-child::after {
//     content: none;
// }
@mixin flex ($value) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: $value;
            flex-direction: $value;
}
@mixin font-size ($target, $context: 16) {
    font-size: ($target / $context) + em;
}
@function count($count) {
    @return (100%/$count);
}
@mixin selection {
    ::-moz-selection { @content };
    ::-o-selection { @content };
    ::selection { @content };
}
@mixin focusInput {
    input:focus, textarea:focus { @content };
}
@if ($resetCSS) {
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
    	margin: 0;
    	padding: 0;
    	border: 0;
    	font-size: 100%;
    	font: inherit;
    	vertical-align: baseline;
    }
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
    	display: block;
    }
    body {
    	line-height: 1;
    }
    ol, ul {
    	list-style: none;
    }
    blockquote, q {
    	quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
    	content: '';
    	content: none;
    }
    table {
    	border-collapse: collapse;
    	border-spacing: 0;
    }
}
@if ($viewport) {
    @-ms-viewport {
      width: device-width;
    }
    @-o-viewport {
      width: device-width;
    }
    @viewport {
      width: device-width;
    }
}
@if ($preset) {
    a {
        text-decoration: none;
    }
    a:hover {
        text-decoration: none;
    }
    input, button, textarea {
        background-color: #fff;
        border: none;
    }
    html {
        -webkit-box-sizing:border-box;
        box-sizing:border-box
    }
    *,*::before,*::after {
        -webkit-box-sizing:inherit;
        box-sizing:inherit
    }
    img {
        max-width: 100%;
        height: auto;
        vertical-align: middle;
    }
    select, label {
        cursor: pointer;
    }
    input[readonly], input:hover[readonly] {
        background-color: rgba(0, 0, 0, 0.2) !important;
        cursor: not-allowed;
    }
    textarea {
        resize: vertical;
    }
    div p {
        max-width: 700px;
    }
    div.selector::before {
        pointer-events: none;
    }
    .disable {
        pointer-events: none;
        opacity: 0.5;
    }
}
@if ($debug) {
    * {
        outline: 1px solid #dd2e2e;
    }
}
@if ($clearfix) {
    .clearfix {
        *zoom: 1;
        &::before, &::after {
            content: "";
            display: table;
        }
        &::after {
            clear: both;
        }
    }
}
/* end.function*/
