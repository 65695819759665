// main: master.scss
/*4 smartphones - landscape */ @media (max-width: 767px) { /*-------------------------------------- smartphones - landscape*/
.table-responsive {
    overflow-x: hidden;
}
#cart_summary {
    thead {
        display: none;
    }
    tfoot tr,
    tbody td {
        display: block;
    }
    tbody td {
        border-bottom: none !important;
        border-right: none;
        border-left: none;
    }
    tbody tr {
        border-top: 2px solid rgb(0, 69, 126);
        display: block;
        position: relative;
    }
    tbody td .quantity-box {
        max-width: 150px;
        margin-left: auto;
    }
    tbody td.cart_description {
        text-align: center;
    }
    tbody td.cart_avail {
        text-align: right;
    }
    tbody td.cart_delete {
        border: 0 !important;
        position: absolute;
        right: 0;
        top: 0;
    }
    tbody td.cart_delete div {
        margin-top: 0;
    }
    tbody td {
        &::before {
            position: absolute;
            left: 10px;
            line-height: 26px;
            font-weight: 500;
        }
        &.cart_avail::before {
            content: "Dostupnosť:";
        }
        &.cart_unit::before {
            content: "Jednotková cena:";
        }
        &.cart_quantity::before {
            content: "Množstvo:";
        }
        &.cart_total::before {
            content: "Celkom:";
        }
    }
    tfoot {
        border-top: 3px solid rgb(0, 69, 126);
    }
    #cart_voucher {
        border-top: 3px solid rgb(0, 69, 126);
    }
    tfoot tr {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        border-bottom: 1px solid rgb(208, 208, 208);
        td {
            width: 50%;
            border: none !important;
        }
    }
    tfoot td.thick-border {
        border-bottom: none !important;
    }
    #send-code,
    #discount_name {
        width: 100%;
    }
    .cart_product img {
        height: 150px;
    }
    tbody td .quantity-box {
        max-width: 100px;
    }
    .unvisible {
        display: none !important;
    }
}
footer {
    .footer-navbar .row {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .footer-navbar .layout-column {
        width: 50%;
        max-width: unset !important;
    }
    a.logo {
        max-width: 200px !important;
        display: block;
    }
}
.product-box .cart-button {
    display: block;
}
.introImg {
    .layout-column.col-lg-12 {
        height: 250px;
    }
}
.ponukaDna {
    .product-box {
        display: block;
    }
    a.product-image {
        width: 100%;
        position: static;
        display: block;
        padding-bottom: 0;
    }
    .product-info {
        width: 100%;
    }
    img.img-responsive.product-img1 {
        max-width: 200px;
    }
}
.productSlider1 {
    a.link.all {
        position: absolute;
        top: auto;
    }
    .owl-stage-outer {
        background-size: 200px;
    }
    .owl-controls .owl-next {
        right: 0 !important;
    }
    .owl-controls .owl-prev {
        left: 0 !important;
    }
    .product-box .cart-button {
        display: none;
    }
}
#category {
    .row.master {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    aside.content-aside.left {
        width: 100%;
        padding-right: 0;
    }
    section#center_column {
        width: 100%;
    }
    .content-aside.left #categories_block_left ul,
    .content-aside.left .layered_filter_ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 0;
    }
    .content-aside.left #categories_block_left ul li,
    .content-aside.left .layered_filter_ul li {
        margin-bottom: 0;
        margin-right: 20px;
    }
    .content-aside.left #layered_block_left #enabled_filters .layered_subtitle h3,
    .content-aside.left #categories_block_left .title_block h3,
    .content-aside.left #enabled_filters .layered_subtitle h3,
    .content-aside.left .layered_subtitle_heading {
        font-size: 22px !important;
        margin-bottom: 0;
    }
    section.page-content,
    section.site-content {
        margin-top: 20px;
    }
    span#layered_price_range {
        margin: 0 10px;
    }
    .categoryHeader .content_scene_cat_bg {
        height: 200px;
    }
    .breadcrumb-box .breadcrumb-inner {
        padding: 0;
    }
}
.primary_block {
    padding-top: 0;
}
#manufacturer,
#product {
    .container-product {
        display: block;
    }
    .pb-right-column,
    .pb-left-column {
        width: 100%;
    }
    img#bigpic {
        max-height: 250px;
    }
    .pb-left-column #image-block {
        padding-left: 0;
    }
    .manufacturerInfo .content {
        display: block;
    }
    .manufacturerInfo .col {
        width: 100%;
        &:last-child {
            padding-left: 0;
        }
    }
    img.map {
        max-width: 300px;
        margin: auto;
        display: block;
        width: 100%;
    }
}
#address form#new_account_form,
#authentication form#new_account_form,
#order-opc form#new_account_form {
    width: 100%;
}
#module-gdpr-consent .rte,
#cms .rte,
#module-gdpr-consent .row.master,
#module-gdpr-delete .row.master,
#module-gdpr-request .row.master,
#module-cashonpickup-validation .row.master,
#order-confirmation .row.master,
#module-cashondeliveryplus-validation .row.master,
#module-bankwire-payment .row.master,
#authentication #center_column,
#discount #center_column,
#identity #center_column,
#opc_new_account #center_column,
#order-slip #center_column,
#history #center_column,
#address #center_column,
#addresses #center_column,
#contactSec1,
#my-account #center_column {
    margin-top: 0;
}
#contactSec1,
#contactSec2 {
    .innerContent {
        display: block;
    }
}
.without-column .product_list .item.ajax_block_product {
    width: count(2);
}
#manufacturers_list li.item {
    width: count(2);
}
}/* end.smartphones - landscape*/
