// main: master.scss
/*fonts ------------------------------------------------------------------------------------------------------------ fonts */;
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300i,700&subset=latin-ext');

$font1: 'PT Sans', sans-serif;
$font2: 'Playfair Display', serif;
$font3: 'Open Sans Condensed', sans-serif;

p, a, label, body {
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
}
h1, h2, h3, h4 {
    font-family: 'PT Sans', sans-serif;
}
body {
    font-size: 100%;
}
/* end.fonts */
