// main: master.scss;
#manufacturer div#categories_block_left {
    display: none;
}
.gatewayButtons {
    margin-bottom: 20px;
    .option {
        width: 100% !important;
        border: 1px solid rgb(171, 171, 171);
        margin-bottom: -1px;
    }
    .logo {
        float: right;
    }
}
input#cgv {
    position: relative !important;
}
.boxTerms p.checkbox {
    background-color: rgb(182, 183, 55) !important;
    padding: 20px;
}
.boxTerms p.checkbox label {
    font-weight: 800 !important;
}
.boxTerms p.checkbox a {
    &:hover {
        color: #000 !important;
        text-decoration: underline;
    }
}
.ponukaDna .offers .old .label {
    visibility: hidden;
}
div#infoShipping {
    display: inline-block;
    background-color: rgb(92, 106, 255);
    padding: 5px 10px;
    margin: 20px 0;
    font-size: 16px;
    color: rgb(255, 255, 255);
}
#module-besterongateway-payment,
#module-gdpr-consent,
#module-gdpr-delete,
#module-gdpr-request,
#module-cashonpickup-validation,
#order-confirmation,
#module-cashondeliveryplus-validation,
#module-bankwire-payment {
    .row.master {
        margin-top: $separator;
    }
}
#discount,
#identity,
#opc_new_account,
#order-slip,
#history,
#address,
#addresses {
    #center_column {
        margin-top: $separator;
    }
}
.alert-warning {
    margin-top: $separator;
}
#opc_account_title,
#opc_new_account {
    margin-top: $separator;
}
#carrier_area {
    margin-top: $separator;
    margin-bottom: $separator;
}
#manufacturers_list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    li.item {
        width: count(4);
    }
    .mansup-container {
        margin-top: $separator;
    }
    .mansup-container .row {
        text-align: center;
    }
    .logo {
        max-width: 150px;
        margin: 0 auto 25px;
    }
}
#contactSec1 {
    margin-top: $separator;
    .innerContent {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .innerContent .col {
        padding-right: 50px;
        margin-bottom: 50px;
        &:last-child {
            padding-right: 0;
        }
    }
    .innerContent h4 {
        font-family: $font2;
        font-weight: 100 !important;
        font-size: 25px !important;
        text-transform: none !important;
        padding: 0;
        margin-bottom: 20px;
    }
    .innerContent a.link {
        font-family: $font1;
        font-weight: 900;
        font-size: 20px;
        text-transform: none !important;
        padding: 0;
        margin-bottom: 20px;
    }
}
#contactSec2 {
    margin-top: $separator/2;
    .innerContent {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .innerContent h2 {
        font-family: $font2;
        font-weight: 100 !important;
        font-size: 33px !important;
        text-transform: none !important;
        padding: 0;
        margin-bottom: 20px;
    }
    .innerContent a.link {
        font-family: $font1;
        font-weight: 900;
        font-size: 20px;
        text-transform: none !important;
        padding: 0;
        margin-bottom: 20px;
    }
    .col1 {
        padding-right: 50px;
    }
    h5 {
        font-size: 20px;
        margin: 0 0 15px;
        padding: 0;
    }
}
#module-gdpr-consent,
#cms {
    .row.master .container {
        max-width: 700px;
    }
    .rte {
        margin: $separator auto 0;
        max-width: 700px;
    }
    h2,
    h2 span {
        font-family: $font2;
        font-weight: 100 !important;
        font-size: 25px !important;
        text-transform: none !important;
        padding: 0;
        margin-bottom: 20px;
        text-align: left;
    }
    ol {
        list-style-type: decimal;
        padding-left: 20px;
        font-size: 16px;
    }
    table td {
        border: 1px solid rgb(154, 154, 154);
    }
    h1 {
        font-family: $font2;
        font-weight: 100 !important;
        font-size: 32px !important;
        text-transform: none !important;
        padding: 0;
        margin-bottom: 20px;
        text-align: left;
    }
}
#views_block {
    width: auto !important;
    padding: 24px 0 !important;
    #thumbs_list ul#thumbs_list_frame {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    li {
        margin-bottom: 0 !important;
        width: auto !important;
        max-width: 100px;
    }
    .bx-controls.bx-has-controls-direction {
        display: none;
    }
    .bx-viewport {
        height: auto !important;
    }
    #thumbs_list li a {
        border: none;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
}
#authentication {
    #center_column {
        margin-top: $separator;
    }
}
#my-account {
    #center_column {
        margin-top: $separator;
    }
    .myaccount-link-list {
        margin-top: 20px;
        text-align: center;
    }
}
#addresses {
    .address.box {
        font-size: 16px;
    }
    .address_update a.btn.btn-default.button {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.address.box {
    font-size: 14px;
}
.blockproductscategory .owl-carousel,
.blockproductscategory .addon-title {
    padding: 0;
}
.checkout-info .shipping {
    display: none;
}
div#attributes {
    display: none;
}
span.text-unverified {
    font-size: 18px;
}
.closeButtonNormal,
.lgcookieslaw_btn-close,
.lgcookieslaw_btn {
    font-size: 13px !important;
    letter-spacing: 2px;
    font-weight: bold;
    padding: 5px 7px;
    display: inline-block;
    border: 0;
    height: 26px;
    line-height: 22px;
    width: auto;
}
.menu-wrap {
    overflow-y: auto;
    -webkit-box-shadow: 5px 0 10px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.2) !important;
    div#off-canvas-title {
        background-color: $color-base;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding-right: 65px;
    }
    .close-button {
        min-width: unset;
        right: 15px;
        top: 12px;
        &::after {
            content: "\f00d";
            display: block;
            font: normal normal normal 14px/1 FontAwesome;
            position: absolute;
            right: 11px;
            top: 0;
            left: auto;
            line-height: 25px;
            color: rgb(255, 255, 255);
            width: auto;
            height: auto;
            background-color: rgba(0, 0, 0, 0);
            -webkit-transform: rotate(0) !important;
            transform: rotate(0) !important;
            font-size: 20px;
        }
    }
    input#ajax_search {
        display: none;
    }
    a.login {
        color: rgb(255, 255, 255);
        &:hover {
            color: #fff !important;
            text-decoration: underline;
        }
    }
}
#lgcookieslaw_banner {
    border: 0;
    border-radius: 0;
    .lgcookieslaw_container {
        height: 44px;
    }
}
