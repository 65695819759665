// main: master.scss;
.link {
    font-family: $font2;
    color: $color-base;
    font-size: 16px;
    text-transform: none;
    &:hover, &:focus {
        color: #000 !important;
    }
}
header .header-top {
    nav a {
        margin-right: 20px;
        color: var(--color-text);
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            text-decoration: underline;
        }
    }
}
header .header-bottom {
    .layout-column {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        width: auto;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        &:nth-child(1) {
            width: 40%;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
        }
        &:nth-child(2) {
            width: 20%;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }
        &:nth-child(3) {
            width: 40%;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
        }
    }
    .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 0;
        &::after,
        &::before {
            display: none;
        }
    }
    a.logo {
        max-width: 200px;
        display: block;
        position: relative;
        top: -10px;
    }
    #jms_ajax_search {
        margin-left: 10px;
        padding-top: 12px;
        .search-box {
            display: none;
        }
        &.open {
            .search-box {
                display: block;
            }
        }
        .search-box {
            position: absolute;
            left: 0;
            width: 250px;
        }
        a.btn-xs.ic-search2 {
            display: none;
        }
    }
    .user-info a {
        font-family: $font2;
        color: $color-base;
        font-size: 20px;
        text-transform: none;
        &:hover, &:focus {
            color: #000 !important;
        }
    }
    .user-info ul {
        margin-bottom: 0;
    }
}
.introText {
    margin-top: $separator + $headerHeight;
    a.btn {
        position: absolute;
        right: 15px;
        top: 30px;
    }
}
.introImg {
    margin-top: $separator;
    .layout-column.col-lg-12 {
        height: 350px;
        &::before {
            content: "";
            background-image: url('../img/italia-colors.jpg');
            height: 3px;
            position: absolute;
            top: -3px;
            display: block;
            width: 100%;
            background-position: center;
        }
    }
}
.ponukaDna {
    margin-top: $separator;
    .product-box {
        border: 1px dashed transparent;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin: 20px;
        -webkit-transition: 1s ease-in-out;
        transition: 1s ease-in-out;
    }
    .owl-item.active .product-box {
        border: 1px dashed $color-base;
    }
    .product-info {
        width: 50%;
        padding: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .product-info .buttons a.link {
        margin-left: 20px;
    }
    a.product-image {
        width: 50%;
        left: -1px;
        bottom: -1px;
        position: relative;
        background-color: #fff;
        margin-top: 50px;
        padding-bottom: 50px;
    }
    img.img-responsive.product-img1 {
        max-width: 300px;
        margin: auto;
    }
    .productName {
        font-family: $font3;
        font-size: 25px;
        padding: 0 0 20px;
    }
    .desc {
        color: $color-alt3;
        padding: 0 0 20px;
        font-size: 16px;
    }
    .offers {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .offers .label {
        color: var(--color-text2);
        display: block;
        padding: 0;
        font-size: 13px;
        text-decoration: none !important;
    }
    .offers .price {
        font-size: 20px;
        font-weight: 700;
        &.new {
            margin-right: 20px;
        }
        &.new .sum {
            color: $color-alt;
        }
        &.old .sum {
            text-decoration: line-through;
            color: $color-alt3;
        }
    }
    .buttons {
        margin-top: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
    }
    a.product-btn.cart-button.ajax_add_to_cart_button.btn2 {
        display: block;
        border-radius: 0;
        width: auto;
        height: auto;
        position: static;
        line-height: normal;
        border-radius: 6px;
    }
    .owl-carousel .owl-item {
    }
    .owl-theme .owl-controls .owl-nav div.owl-prev,
    .owl-theme .owl-controls .owl-nav div.owl-next {
        top: calc(50% - 20px);
    }
}
.productSlider1 {
    margin-top: $separator;
    .owl-item {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
        &.active {
            -webkit-transform: scale(0.5);
            transform: scale(0.5);
            opacity: 0.5;
            -webkit-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;
        }
        &.center {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
            .shopIt {
                visibility: visible;
                opacity: 1;
                display: block !important;
            }
        }
    }
    .owl-stage-outer {
        background-image: url('../img/Barriccaia_della_Cantina_Einaudi.png');
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 380px;
    }
    .owl-stage {
        pointer-events: none;
    }
    .product-box .product-info {
        pointer-events: auto;
    }
    .product-box,
    .catSlider .owl-item {
        background-color: rgba(0, 0, 0, 0);
    }
    .owl-carousel .owl-item img {
        -webkit-filter: drop-shadow(0px 5px 10px rgba(0,0,0,0.5));
        filter: drop-shadow(0px 5px 10px rgba(0,0,0,0.5));
        padding-bottom: 20px;
    }
    .product-carousel-featured {
        margin-top: 30px;
    }
    .owl-controls {
        .owl-prev {
            left: 30% !important;
        }
        .owl-next {
            right: 30% !important;
        }
    }
    a.link.all {
        position: absolute;
        right: 0;
        top: 0;
    }
    .product-box:hover .cart-button,
    .categoryIcon,
    a.product-btn.cart-button.btn-default.ajax_add_to_cart_button.btn2,
    span.weight {
        display: none;
    }
}
.product-box {
    .product-info a.product-name {
        font-family: $font3;
        color: var(--color-text2);
        font-size: 25px !important;
        text-transform: none;
        line-height: 34px;
        text-shadow: 0px 0px 10px white, 0px 0px 15px white, 0px 0px 20px white;
        &:hover, &:focus {
            color: #000 !important;
        }
    }
    .product-info .content_price .price.old {
        margin-right: 0;
        font-size: 18px;
        margin-left: 10px;
    }
    .product-info .product-cat {
        display: block;
        text-align: center;
        font-size: 16px;
        text-shadow: 0px 0px 10px white, 0px 0px 15px white, 0px 0px 20px white;
    }
    .product-info .product-cat a {
        font-size: 12px;
    }
    .shopIt {
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity 0.3s, visibility 0.3s ease;
        transition: opacity 0.3s, visibility 0.3s ease;
    }
    a.product-btn.cart-button.ajax_add_to_cart_button {
        text-decoration: none;
        min-width: unset;
    }
    .product-box .cart-button .icon-basket {
        font-size: 33px;
    }
    &:hover {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .categoryIcon {
        position: absolute;
        right: 20px;
        z-index: 1;
    }
}
.productSlider2 {
    margin-top: $separator;
}
#product_list,
.productSlider2 {
    .product-box {
        padding: 20px;
        border: 1px dashed transparent;
        &:hover {
            border: 1px dashed rgba(0, 0, 0, 0.2);
            -webkit-box-shadow: 0px 0px 150px 10px rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0px 0px 150px 10px rgba(0, 0, 0, 0.15) !important;
        }
    }
    a.product-btn.cart-button.ajax_add_to_cart_button.btn2 {
        width: auto;
        margin: auto;
        position: static;
        border-radius: 6px;
        line-height: 27px;
    }
    .cart-btnWrap {
        position: absolute;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        left: 0;
        bottom: 15px;
        .disable {
            opacity: 0;
        }
    }
    .product-box .product-info a.product-name {
        text-align: left;
        text-overflow: unset;
        white-space: normal;
        display: block;
        height: 65px;
    }
    .product-box .product-info .product-cat {
        margin-top: 0;
        margin-bottom: 5px;
        text-align: left;
        font-size: 16px;
    }
    .product-box .product-info .content_price {
        text-align: left;
        line-height: 20px;
        margin-top: 0;
    }
    .product-box .product-info {
        padding-bottom: 20px;
        margin-top: 20px;
    }
    span.weight {
        position: absolute;
        right: 20px;
        font-size: 15px;
    }
}
.infoPanel {
    background-color: rgb(239, 239, 239);
    margin-top: $separator;
    padding: $separator 0;
    .feature-box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 200px;
        margin: 0 20px;
    }
    .feature-box:hover .feature-img {
        -webkit-transform: none !important;
        transform: none !important;
    }
    .feature-img {
        margin-bottom: 20px;
    }
    h3 {
        font-family: $font2;
        color: #000;
        font-size: 35px;
    }
    .feature-content {
        text-align: center;
    }
    p {
        color: var(--color-text);
    }
    .layout-column {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        max-width: 1500px;
    }
}
footer {
    margin-top: $separator !important;
    .preFooter {
        padding: $separator/2 0;
        .addon-box {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
        h2 {
            font-family: $font2;
            color: var(--color-text2);
            font-weight: 100;
            font-size: 25px;
            text-transform: none;
            font-style: italic;
            padding: 0;
        }
        p {
            margin: 0;
        }
        a.icon {
            font-size: 0;
            display: block;
            height: 2rem;
            &::before {
                content: "";
                display: block;
                width: 2rem;
                height: 2rem;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;;
            }
            &.icon-facebook::before {
                background-image: url(../img/icon-facebook.svg);
            }
        }

    }
    .footer-navbar {
        .container {
            padding: $separator/2 0;
            border-top: 1px solid rgb(215, 222, 221);
        }
        .layout-column {
            width: auto;
            &:last-child {
                text-align: right;
            }
        }
        img.logo.img-responsive {
            max-width: 200px;
        }
    }
}
#cart_block {
    .dropdown-toggle .cart_block_total {
        font-size: 18px;
        font-weight: 600;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
}
