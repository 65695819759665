// main: master.scss
/*1 all */ @media (min-width: 1px) { /*-------------------------------------------------------------------------------- all*/
$separator: 100px;
$headerHeight: 112px;

@import "1A-all";
@import "1B-all";
@import "1C-all";

// .quantity-box,
// .shopIt,
// #cart_block,
// .cart-button {
//     display: none !important;
// }
table.paymentTable {
    width: 100%;
    margin-top: 20px;
    thead {
        background-color: $color-base;
        color: rgb(255, 255, 255);
    }
    thead td {
        border: 1px solid $color-base;
    }
    td {
        font-size: 16px;
        border: 1px solid rgb(173, 173, 173);
        width: 100%;
        white-space: nowrap;
        &:first-child {
            white-space: normal;
        }
    }
    td.text-right {
        text-align: right;
    }
    td.text-wrap {
        white-space: normal;
    }
    h3 {
        padding: 0;
        font-size: 13px;
    }
}
table.paymentTableSum {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    background-color: $color-alt;
    td {
        font-size: 16px;
        border: 1px solid #fff;
        color: #fff;
        white-space: nowrap;
    }
    td.text-right {
        text-align: right;
    }
}
#my-account {
    .myaccount-link-list li {
        width: 50%;
        float: unset;
        margin: 10px auto;
        padding: 0;
    }
    .page-heading {
        border-bottom: none;
        text-align: center;
    }
    .addresses-lists p {
        text-align: center;
        margin: auto;
    }
}
.jms-slider-wrapper .fs_loader {
    height: 470px;
}
#scrollToTop {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgb(0, 0, 0);
    border-radius: 50%;
    z-index: 999;
    &:hover {
        opacity: 0.9 !important;
        padding-bottom: 10px;
    }
    &:after {
        content: "\f077";
        color: rgb(255, 255, 255);
        font-family: FontAwesome;
        font-size: 14px;
    }
}
#cookieNotice {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    .closeFontAwesome {
        &:hover:before, &:focus:before {
            color: $color-alt;
        }
    }
}
#identity,
#addresses,
#address {
    li.address_update {
        text-align: center;
    }
    .main-page-indent {
        text-align: center;
    }
    .page-subheading {
        text-align: left;
    }
    .page-heading {
        border-bottom: none;
        text-align: center;
    }
    .box label,
    .box p,
    form p {
        font-size: 1.3em;
        line-height: 2.7em;
    }
    section.page-content,
    #center_column {
        width: 100%;
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
    }
    p.submit2 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}
#authentication {
    .page-heading {
        border-bottom: none;
        text-align: center;
    }
    .box label,
    .box p,
    form p {
        font-size: 1.3em;
        line-height: 2.7em;
    }
    #noSlide {
        width: 100%;
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
    }
}
#module-besterongateway-payment,
#guest-tracking,
#order-confirmation,
#password,
#module-cashonpickup-validation,
#module-cashondeliveryplus-validation,
#module-cheque-payment,
#module-bankwire-payment {
    .page-heading {
        border-bottom: none;
        text-align: center;
    }
    p.submit {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .footer_links,
    .cart_navigation {
        margin-top: 15px;
        margin-bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .cart_navigation button.exclusive_large,
    .cart_navigation input.exclusive_large {
        margin-bottom: 0 !important;
    }
    .box label,
    .box p,
    form p {
        font-size: 16px;
        line-height: 26px;
    }
    form a:hover {
        text-decoration: underline;
    }
    .exclusive_large {
        margin-left: 20px;
        white-space: normal;
    }
    section.page-content,
    #center_column {
        width: 100%;
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
    }
    section.page-content .row {
        padding: 0 20px;
    }
}
#address,
#authentication,
#order-opc {
    div#uniform-id_address_delivery {
        display: inline-block;
    }
    li.address_update {
        text-align: right;
    }
    .address.box li,
    .addresses p {
        font-size: 1.3em;
    }
    .boxTerms {
        font-size: 1.8em;
        margin: 30px 0 10px;
    }
    .boxTerms p {
        max-width: unset;
    }
    .boxTerms div.checker {
        margin-bottom: 0;
    }
    .boxTerms .checkbox label {
        display: inline;
    }
    .orderNote {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        margin: 20px 0 0;
    }
    .orderNote p {
        font-size: 1.3em;
        white-space: nowrap;
        padding: 0 10px 0 0;
    }
    .carrierTarget tr {
        td.delivery_option_radio {
            color: $color-base !important;
            background-color: $color-alt !important;
        }
        &:hover td.delivery_option_radio, &:focus td.delivery_option_radio {
            color: $color-alt !important;
            background-color: $color-base !important;
        }
    }
    .carrierTarget {
        width: 100%;
        margin-bottom: 0;
    }
    td.delivery_option_radio {
        text-align: center;
        width: 20px;
    }
    div.radio span {
        position: relative;
        margin-top: 8px;
    }
    td.delivery_option_logo {
        width: 100px;
        text-align: center;
    }
    td.delivery_option_price {
        width: 200px;
        text-align: center;
    }
    img.order_carrier_logo {
        width: 70px;
    }
    table.resume.table.table-bordered {
        margin-bottom: -1px;
    }
    h1#cart_title {
        padding-top: 0;
    }
    .cart_navigation {
        margin-top: 0;
        margin-bottom: 0;
    }
    #opc_account_choice,
    .customerAuth {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .customerAuth fieldset {
        padding: 0;
    }
    #opc_account_choice .col-xs-12.col-md-6 {
        padding: 0;
        width: auto;
    }
    #opc_account_choice .col-xs-12.col-md-6 p.opc-button {
        text-align: center;
        margin-bottom: 20px;
    }
    a#openLoginFormBlock,
    button#opc_createAccount,
    button#opc_guestCheckout {
        margin: auto;
        min-width: 250px;
    }
    #opc_account_form {
        font-size: 1.3em;
    }
    form#new_account_form {
        width: 50%;
    }
    #opc_delivery_methods,
    form #opc_account_form {
        width: 100%;
        max-width: 550px;
        margin: auto;
    }
    form label {
        margin-bottom: 0px;
    }
    form .date-select .col-xs-4 {
        width: 103px;
        margin: 5px 10px 0;
    }
    form h3.page-subheading.top-indent {
        margin-top: 50px;
        text-align: center;
    }
    form div#uniform-id_country_invoice,
    form div#uniform-id_country_invoice span,
    form div#uniform-id_country,
    form div#uniform-id_country span {
        width: 100% !important;
        line-height: 1em;
    }
    form button#submitAccount,
    form button#submitGuestAccount {
        float: right;
    }
    form#login_form {
        font-size: 1.3em;
    }
    div#HOOK_PAYMENT {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    form .address2,
    form .optin,
    form .newsletter,
    form .date-select,
    form .gender-line {
        display: none;
    }
}
#cart_summary {
    font-size: 1.3em;
    .cart_product img {
        height: 60px;
        width: auto;
    }
    td.cart_delete.text-center i {
        font-size: 1.1em;
        &:hover, &:focus {
            color: red;
        }
    }
    tfoot td {
        vertical-align: middle;
    }
    tfoot td.price,
    li.price,
    span.price {
        text-align: right;
        font-size: 1.6em;
    }
    li.old-price {
        text-decoration: line-through;
    }
    thead {
        background-color: $color-alt2;
    }
    .quantity-box input.cart_quantity_input {
        border-bottom: none !important;
    }
    td.cart_product {
        text-align: center;
    }
    td.cart_total {
        text-align: right !important;
    }
    p.product-name {
        margin-bottom: 0;
        font-size: 1.4em;
    }
    td#total_price_container {
        font-weight: bold;
    }
    #cart_voucher button {
        float: right;
        margin-top: 20px;
    }
    .cart_quantity_button {
        padding-top: 0 !important;
    }
}
sup {
    color: rgb(255, 0, 0);
    font-size: 1.5em;
    top: 1px;
}
#views_block {
    padding: 24px 0 !important;
    li {
        margin-bottom: 0 !important;
    }
}
#product {
    h1 {
        margin-top: 0;
    }
    .quantity-box #quantity_wanted_p {
        border: 1px solid $color-base;
        border-radius: 6px;
    }
    .btn-effect:hover:after {
        display: none;
    }
    img#bigpic {
        max-height: 600px;
        width: auto;
    }
    .pb-left-column {
        width: 40%;
    }
    .pb-right-column {
        width: 60%;
    }
    .container-product {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    table.productFeatures td, table.productFeatures th {
        border: 1px solid rgb(255, 156, 0);
        font-size: 1.4em;
    }
}
.breadcrumb-box {
    .breadcrumb-inner {
        padding: 0 0 10px 0;
        text-align: left;
        color: rgb(0, 0, 0);
        font-size: 14px;
    }
    .breadcrumb-inner .navigation-pipe:before {
        content: ">";
    }
    .breadcrumb-inner a {
        color: $color-base;
        text-transform: none;
        &:hover, &:focus {
            color: #000 !important;
            text-decoration: underline;
        }
    }
}
.cart-wrap .description .price-quantity,
.product-box .product-info .content_price .price.new,
.our_price_display .price {
    color: $color-alt;
    font-size: 20px;
    font-weight: 700;
    text-shadow: 0px 0px 10px white, 0px 0px 15px white, 0px 0px 20px white;
}
.owl-controls {
    .owl-prev::after,
    .owl-next::after {
        font-family: FontAwesome;
        width: 100%;
        height: 100%;
        z-index: 99999999999999;
        font-size: 16px;
        line-height: 40px;
    }
    .owl-next::after {
        content: "\f178";
        font-size: 30px;
    }
    .owl-prev::after {
        content: "\f177";
        font-size: 30px;
    }
}
.product-box .cart-button {
    background-image: none;
    background-color: transparent;
    color: $color-base;
    &:hover, &:focus {
        color: #fff !important;
        background-image: none !important;
        background-color: rgba(0, 0, 0, 0);
    }
}
.owl-theme .owl-controls .owl-nav div.owl-prev,
.owl-theme .owl-controls .owl-nav div.owl-next {
    background-image: none;
    background-color: transparent;
    color: $color-base;
    border: none;
    &:hover, &:focus {
        color: $color-alt !important;
        background-image: none !important;
        background-color: rgba(0, 0, 0, 0);
        border: none;
    }
}
.product-box .preview .label {
    left: 0 !important;
    right: unset !important;
}
.productscategory-carousel,
.product-carousel-featured {
    .product-box .action-btn .box {
        border-top: none;
    }
    .product-box {
        margin: 0 !important;
        &:hover, &:focus {
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
    .owl-stage-outer {
        overflow: hidden !important;
        padding: 0 !important;
    }
}
.catSlider {
    .btn-hover:hover {
        background: none !important;
        color: $color-alt3 !important;
    }
    a {
    &:hover, &:focus {
        text-decoration: underline;
        }
    }
    a.btn-show-all.btn-hover {
        position: absolute;
        bottom: 5px;
        right: 5px;
        padding: 0 5px;
        font-size: 1.2em;
    }
    ul.child-list {
        padding: 5px 10px;
        margin: 0 0 20px;
    }
    .owl-item {
        background-color: $color-alt2;
    }
    .owl-stage {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    li {
        display: inline-block;
        padding: 0 10px 0 0;
        font-size: 1.3em;
        &::after {
            content: "/";
            margin: 0 0 0 5px;
        }
        &:last-child::after {
            display: none;
        }
    }
    .categoy-image a {
        display: block;
        position: relative;
    }
    .categoy-image {
        img {
            -webkit-filter: grayscale(100%) brightness(24%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(0.8);
            filter: grayscale(100%) brightness(24%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(0.8);
        }
        &:hover img, &:focus img {
            -webkit-filter: grayscale(0%) brightness(44%) sepia(0%) hue-rotate(0deg) saturate(100%) contrast(0.8);
            filter: grayscale(0%) brightness(44%) sepia(0%) hue-rotate(0deg) saturate(100%) contrast(0.8);
        }
    }
    .cat-name {
        font-size: 1.4em;
        font-weight: bold;
        position: absolute;
        width: 100%;
        top: 0;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: rgb(255, 255, 255);
        text-align: center;
    }
}
.content-aside.left {
    #layered_block_left #enabled_filters .layered_subtitle {
        float: left !important;
        width: 100%;
    }
    ul {
        margin-bottom: 0;
    }
    #layered_form div.checker,
    #layered_block_left li.nomargin > label,
    #layered_block_left .layered_filter_ul li a {
        display: inline;
    }
    #layered_form div.checker {
        margin-left: 15px;
    }
    #layered_form div.checker input {
        cursor: pointer;
    }
    .layered_filter ul li span {
        top: -0.3em;
    }
    #layered_block_left .layered_filter_ul li a,
    #categories_block_left .block_content>ul>li>a {
        color: #000;
        &:hover, &:focus {
            text-decoration: underline;
        }
    }
    #layered_block_left .layered_filter_ul.layer-design,
    #layered_block_left .layered_filter,
    #layered_block_left .layered_price,
    #categories_block_left {
        margin-bottom: 20px;
    }
    #layered_block_left .layered_slider_container {
        margin-bottom: 10px;
        width: 50%;
    }
    #layered_block_left #enabled_filters .layered_subtitle h3,
    #categories_block_left .title_block h3 {
        text-transform: unset;
        padding: 0;
    }
    #layered_block_left #enabled_filters .layered_subtitle,
    #categories_block_left .title_block {
        border-bottom: none;
        margin-bottom: 10px;
    }
    #layered_block_left #enabled_filters .layered_subtitle h3:before,
    #categories_block_left .title_block h3::before {
        display: none;
    }
    .ui-widget-content {
        width: calc(100% - 17px);
    }
    #categories_block_left li span.grower {
        right: 6px;
        top: 7px;
        line-height: 20px;
        width: 20px;
        height: 20px;
        border: none;
        border-radius: 50%;
        padding-right: 1px;
        color: $color-base;
        background-color: $color-alt;
        &:hover, &:focus {
            color: $color-alt;
            background-color: $color-base;
        }
        &::before {
            color: $color-base;
        }
        &:hover::before, &:focus::before {
            color: $color-alt;
        }
    }
}
.page-content {
    .product_list p {
        text-align: center;
        margin-bottom: 50px;
        max-width: unset;
    }
    .product-sort {
        padding-right: 15px;
    }
    .filters-panel {
        margin-top: 0;
        padding-bottom: 80px;
        border-top: 0;
        border-bottom: 0;
        &.bottom {
            padding-bottom: 0;
        }
    }
    .content_scene_cat_bg .cat_desc h3 {
        font-size: 5em;
        color: $color-base;
        padding: 0;
    }
    .content_scene_cat_bg .cat_desc .flex-box {
        max-width: unset;
    }
    .content_scene_cat_bg {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 300px;
        min-height: unset !important;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 100px;
    }
}
section.page-content,
section.site-content {
    margin-top: 143px;
    p {
        font-size: 16px;
    }
    ul.footer_links.clearfix {
        text-align: center;
    }
    .page-heading {
        padding: 0;
        line-height: 28px;
    }
    .warning, .alert {
        font-size: 1.3em;
        max-width: unset;
    }
    .addon-title {
        position: relative;
        &::before {
            display: none;
        }
    }
    .page-heading,
    .blockproductscategory .addon-title h3,
    .addon-title h3 {
        font-family: $font2;
        color: var(--color-text);
        font-size: 45px;
        line-height: 45px;
        padding: 0 0 20px;
        text-transform: unset;
        margin-top: 0;
        border: none;
        &::before {
            display: none;
        }
    }
    p.addon-desc {
        color: var(--color-text);
    }
}
.container {
    width: 90%;
    max-width: 1500px;
}
footer {
    background-color: #fff;
    #social_block {
        margin: 20px 0 0 0;
    }
    #social_block .fa {
        color: rgb(255, 255, 255);
    }
    section.blockcategories_footer.footer-block {
        margin: 0 0 20px 0;
    }
    .blockcategories_footer a,
    .footer-navbar p {
        font-size: 1.3em;
        margin-bottom: 0;
    }
    .footer-navbar a {
        &:hover, &:focus {
            text-decoration: underline;
        }
    }
    .footer-navbar .layout-column {
        &::after {
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            background-color: rgb(232, 232, 232);
            position: absolute;
            right: 0;
            top: -10px;
            padding-bottom: 10px;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
        }
        &:last-child::after {
            display: none;
        }
    }
    .footer-navbar h3 {
        font-family: $font2;
        color: var(--color-text2);
        font-weight: 100;
        font-size: 20px;
        text-transform: none;
        font-style: italic;
    }
    .footer-navbar .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        &::before,
        &::after {
            display: none;
        }
    }
    iframe {
        border: none;
    }
    .category_footer ul ul {
        display: none !important;
    }
    .footer-copyright {
        padding: 15px 0 5px;
        background-color: rgb(152, 152, 152);
    }
    #footer_thebricks_line {
        text-align: left;
        line-height: 2em;
        font-size: 1.3em;
        max-width: unset;
    }
    #footer_thebricks_link {
        text-decoration: none;
        font-family: "Myriad Pro", Myriad, sans-serif !important;
        font-weight: bold;
        margin-left: 3px;
        letter-spacing: 0.5px;
        white-space: nowrap;
        color: #000;
    }
    #footer_thebricks_link:hover {
        color: $color-base !important;
    }
    #footer_thebricks_link:hover {
        padding: 4px 0px 1px 0px;
    }
    #footer_thebricks_THE {
        padding: 4px 3px 1px 0px;
        margin-left: -2px;
    }
    #footer_thebricks_BRICKS {
        border: 2px solid;
        padding: 3px 3px 1px 3px;
        margin-right: -2px;
    }
}
header {
    position: fixed;
    width: 100%;
    z-index: 8000;
    top: 0;
    .header-top .right .addon-box:nth-child(3) {
        margin-right: 0;
    }
    .layout-column.col-lg-2.col-md-2.col-sm-12.col-xs-12.left {
        padding-left: 0;
    }
    .checkout-info .label,
    .checkout-info .pull-right {
        color: rgb(0, 0, 0);
    }
    #cart_block .shoppingcart-box {
        max-height: 70vh;
    }
    .jms-megamenu .open > .dropdown-menu::after,
    #cart_block .shoppingcart-box::before {
        background-color: $color-base;
    }
    #cart_block a.cart-icon {
    padding: 6px 0px 6px 20px;
    }
    #cart_block .shoppingcart-box::after, .jms-megamenu .nav > li.open > a::after {
        border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color-base;
    }
    #cart_block .shoppingcart-box {
        background: rgba(0, 0, 0, 0);
        padding: 9px 0 0;
        -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
        z-index: 8001;
    }
    #cart_block .cart_block_list,
    #cart_block .checkout-info,
    #cart_block span.ajax_cart_no_product {
        background-color: #fff;
        padding: 10px 20px 20px;
    }
    #cart_block .checkout-info .cart-button {
        text-align: center;
    }
    #cart_block .ajax_cart_quantity:hover {
        color: rgb(255, 255, 255) !important;
    }
    #cart_block .ajax_cart_quantity {
        right: -8px;
        top: 3px;
        background-color: $color-base;
    }
    #cart_block:hover, #cart_block:focus {
        .dropdown-toggle .cart_block_total,
        span.icon-basket {
            color: $color-alt;
        }
    }
    #jms_ajax_search #ajax_search:focus {
        outline: none;
    }
    #jms_ajax_search #search_result {
        -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
        left: 10%;
        width: 80%;
        margin: 2px 0 0;
    }
    a.btn-xs {
        font-size: 1.3em;
    }
    .navbar-fixed-top {
        top: 0;
        position: relative;
        top: auto;
        -webkit-animation-name: unset !important;
        animation-name: unset !important;
    }
    .header-bottom {
        background-color: #fff !important;
        -webkit-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        border-bottom: 1px solid rgb(228, 228, 228) !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    &.sticky .header-bottom {
        -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1) !important;
    }
    .header-top .right .addon-box:nth-child(2) {
        margin-left: 20px;
    }
    .header-top .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .jms-megamenu .nav > li > a {
        font-family: $font2;
        color: var(--color-text2);
        font-size: 20px;
        font-style: italic;
        text-transform: none;
        padding: 20px 40px 20px 0;
        &:hover, &:focus {
            color: $color-base !important;
        }
    }
    .nav {
        text-align: left;
    }
    .header-top {
        padding: 5px 0;
        background-color: #fff;
    }
    .header-top .right .addon-box:nth-child(3):after {
        display: none;
    }
    #cart_block .icon-basket,
    a.dropdown-toggle.cart-icon span,
    .header-top .right .user-info ul li a {
        color: var(--color-text2);
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        &:hover, &:focus {
            color: $color-alt !important;
        }
    }
    .header-bottom #jms_ajax_search .search-box {
        left: -20px;
        top: 40px;
    }
    #jms_ajax_search #ajax_search {
        background: $color-base;
        color: rgb(255, 255, 255) !important;
    }
}
.form-ok::after {
    content: "a";
    content: "\f00c";
    float: right;
    margin-top: -30px;
    margin-right: 10px;
    font-family: "FontAwesome";
    color: rgb(0, 128, 0);
}
.form-error::after {
    content: "a";
    content: "\f00d";
    float: right;
    margin-top: -30px;
    margin-right: 10px;
    font-family: "FontAwesome";
    color: red;
}

.product-box .product-info a.product-name:hover,
a {
    color: $color-text;
    &:hover, &:focus {
        color: $color-alt3 !important;
    }
}
.table-bordered,
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
    border: 1px solid $color-border;
}
.myaccount-link-list li a,
.btn-default,
.checkout-info .cart-button > a,
.btn,
button,
input[type="submit"] {
    display: inline-block;
    color: $color-base !important;
    border: 1px solid $color-base !important;
    font-family: $font2;
    cursor: pointer;
    min-width: 150px;
    height: auto;
    text-align: center;
    padding: 5px 20px !important;
    background-image: none !important;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 15px;
    line-height: 27px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0) !important;
    &:hover, &:focus {
        color: #fff !important;
        background-color: $color-base !important;
    }
    &::before, &::after {
        display: none;
    }
}
.quantity-box #add_to_cart,
.btn2 {
    display: inline-block;
    color: #fff !important;
    border: 1px solid $color-base;
    background-color: $color-base !important;
    font-family: $font2;
    cursor: pointer;
    min-width: 150px;
    height: auto;
    text-align: center;
    padding: 5px 20px !important;
    background-image: none !important;
    letter-spacing: 1px;
    font-weight: 100;
    font-size: 15px;
    line-height: 27px;
    border-radius: 6px;
    text-transform: uppercase;
    &:hover, &:focus {
        background-color: $color-alt !important;
        border: 1px solid $color-alt !important;
    }
    &::before, &::after {
        display: none;
    }
}
textarea.form-control {
    border: 1px solid $color-alt;
    &:hover, &:focus {
        border: 1px solid $color-base;
    }
    &:focus {
        outline: 1px solid rgba(0, 0, 0, 0.2);
    }
}
#address form div#uniform-id_country span,
#order-opc form div#uniform-id_country_invoice span,
#order-opc form div#uniform-id_country span,
.woocommerce .quantity input.qty,
input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select {
    color: $color-text !important;
    background-color: transparent;
    padding: 0.7em;
    font-size: 1.3em;
    width: 100%;
    border-color: transparent;
    border-bottom: 1px solid $color-alt;
    &:hover, &:focus {
        border-color: transparent;
        border-bottom: 1px solid $color-base;
        background-color: transparent;
    }
    &:focus {
        outline: 1px solid rgba(0, 0, 0, 0.2);
    }
}
#order-opc form div#uniform-id_country_invoice,
#order-opc form div#uniform-id_country {
    &:hover span, &:focus span {
        border-color: transparent;
        border-bottom: 1px solid $color-base;
        background-color: transparent;
    }
    &:focus span {
        outline: 1px solid rgba(0, 0, 0, 0.2);
    }
}
/*scrollbar*/
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }
    ::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border: 0px none #ffffff;
        border-radius: 0;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #bfbfbf;
    }
    ::-webkit-scrollbar-thumb:active {
        background: $color-base;
    }
    ::-webkit-scrollbar-track {
        background: #666666;
        border: 0px none #ffffff;
        border-radius: 0px;
    }
    ::-webkit-scrollbar-track:hover {
        background: #666666;
    }
    ::-webkit-scrollbar-track:active {
        background: #333333;
    }
    ::-webkit-scrollbar-corner {
        background: transparent;
    }
}/* end.all*/

/*
    color: lighten( $color-base, 20%); - vygeneruje odvodenú farbu
    width: count(x); - vypočíta šírku bloku podľa celkového počtu horizontálnych blokov
    @include font-size(x); - veľkosť písma v relatívnych jednotkách em
    @include flex(x); - display: flex + row/column
*/
