// compileCompressed: mausstyle.min.css; compileExpanded: temp.css;
$version: "1.0";/*!This CSS is generated by mausStyle version #{$version}. Please edit the SASS (scss) files*/

$resetCSS : false;       // reset all HTML elements to null
$preset : true;          // preset for any some HTML elements
$debug : false;          // set all HTML element red outline
$clearfix : true;        // activate special class for group with float HTML elements
$focusInput : true;      // custom focus effect for input element
$viewport : true;        // viewport for responsive design

@import "https://necolas.github.io/normalize.css/7.0.0/normalize.css";
@import "function";
@import "colors";
@import "fonts";
@import "1-all";
@import "2-desktop";
@import "3-tablets";
@import "4-smartphones-landscape";
@import "5-smartphones-portrait";
@import "6-special";
@import "animation";
