// main: master.scss
/*special */ @media (min-width: 960px) and (max-width: 1239px) { /*------------------------------------------------ special*/
.special {
}
}/* end.special*/
/*special */ @media (min-width: 1025px) and (max-width: 1200px) { /*------------------------------------------------ special*/
.infoPanel {
    .feature-box {
        width: 140px;
    }
}
#address div#HOOK_PAYMENT,
#authentication div#HOOK_PAYMENT,
#order-opc div#HOOK_PAYMENT {
    display: block;
    text-align: center;
    .col {
        display: block;
        p.button-exclusive.btn.btn-default.payment_module {
            margin-bottom: 30px;
            white-space: normal;
        }
        &:last-child {
            p.button-exclusive.btn.btn-default.payment_module {
                margin-bottom: 0;
            }
        }
    }
}
}/* end.special*/
/*special */ @media (max-width: 360px) { /*------------------------------------------------ special*/
.productSlider1 {
    .owl-item.center {
        max-width: 270px;
    }
}
.categoryHeader {
    margin-top: 62px !important;
}
}/* end.special*/
/*special */ @media (min-width: 992px) and (max-width: 1150px) { /*------------------------------------------------ special*/
header {
    .jms-megamenu li.menu-item {
        padding: 0 20px 0 0;
    }
    .jms-megamenu .dropdown-menu ul.mega-nav img {
        max-width: 140px;
    }
    .jms-megamenu .dropdown-menu li.menu-item {
        padding: 0px;
    }
}
}/* end.special*/
/*special */ @media (max-width: 991px) { /*------------------------------------------------ special*/
footer {
    .preFooter .addon-box {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .preFooter h2 {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
    .preFooter p {
        margin: 0 10px 10px;
    }
}
.introText {
    margin-top: 0;
    a.btn {
        position: relative;
        right: auto;
        top: 20px;
    }
}
header {
    .header-top {
        display: none;
    }
    .menu-button {
        width: 30px;
        height: 30px;
        min-width: unset;
        padding: 0 !important;
        top: 0 !important;
        position: static;
        border: none !important;
        &:hover {
            background-color: transparent !important;
        }
    }
    .header-bottom .user-info a {
        font-size: 14px;
    }
    .menu-button .fa {
        line-height: 30px;
    }
    .jms-row.header-bottom.fixed-top {
        padding: 10px 0;
    }
    .header-bottom a.logo {
        top: 0;
        max-width: 150px;
    }
    .header-bottom #jms_ajax_search {
        padding-top: 9px;
    }
    .header-bottom .layout-column:nth-child(2) {
        width: auto;
    }
    .btn-group.compact-hidden.user-info {
        display: none;
    }
    span.cart_block_total.ajax_block_cart_total.pull-right {
        display: inline !important;
    }
}
#cookieNotice tr,
#lgcookieslaw_banner .lgcookieslaw_container {
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 0;
}
.categoryHeader {
    margin-top: 67px;
    position: relative;
}
}/* end.special*/
