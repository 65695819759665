// main: master.scss
/*animation ---------------------------------------------------------------------------------------------------- animation */
#scrollToTop {
    transition: padding 0.5s, opacity 0.5s ease;
    -moz-transition: padding 0.5s, opacity 0.5s ease;
    -ms-transition: padding 0.5s, opacity 0.5s ease;
    -webkit-transition: padding 0.5s, opacity 0.5s ease;
    -o-transition: padding 0.5s, opacity 0.5s ease;
}
td.cart_delete.text-center i,
td.delivery_option_radio,
.btn-default,
.quantity-box #add_to_cart,
#categories_block_left li span.grower,
.productSlider .owl-item img.img-responsive.product-img1,
.owl-theme .owl-controls .owl-nav div.owl-prev,
.owl-theme .owl-controls .owl-nav div.owl-next,
.jms-megamenu .nav > li,
a, a:visited, a:focus {
    transition: color 0.5s, background 0.5s, background-color 0.5s ease;
    -moz-transition: color 0.5s, background 0.5s, background-color 0.5s ease;
    -ms-transition: color 0.5s, background 0.5s, background-color 0.5s ease;
    -webkit-transition: color 0.5s, background 0.5s, background-color 0.5s ease;
    -o-transition: color 0.5s, background 0.5s, background-color 0.5s ease;
}
.hoverScale {
    display: block;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    -webkit-backface-visibility: visible;
    &:hover, &:focus {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
/*
.class {
    animation-name: fadein;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
/* end.animation*/
