// main: master.scss;
.category-nealko div#categories_block_left,
.category-syry div#categories_block_left,
.category-salamy div#categories_block_left,
.category-produkty-s-hluzovkou div#categories_block_left,
.category-kava div#categories_block_left,
.category-cestoviny-a-ryza div#categories_block_left,
.category-produkty div#categories_block_left,
.category-vino div#categories_block_left {
    display: none;
}
.primary_block {
    padding-top: $separator;
    #image-block {
        width: 100%;
        text-align: center;
    }
    .pb-right-column h1 {
        font-family: $font3;
        color: var(--color-text2);
        font-weight: 100;
        font-size: 35px;
        text-transform: none;
        padding: 0;
        margin-bottom: 15px;
    }
    .pb-right-column h3 {
        font-family: $font2;
        color: var(--color-text2);
        font-weight: 100;
        font-size: 20px;
        text-transform: none;
        font-style: italic;
        padding: 0;
        margin-bottom: 10px;
    }
    div#description_block {
        margin-bottom: 25px;
        line-height: 29px;
        font-size: 16px;
    }
    .heading {
        margin-bottom: 20px;
    }
    div#availability_statut {
        color: var(--color-text);
        font-size: 18px;
    }
    #set .items a {
        margin-right: 20px;
    }
    #old_price {
        margin-right: 0;
    }
    #price {
        margin-right: 20px;
    }
    .content_prices {
        margin-bottom: 10px;
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }
    span.price {
        font-size: 20px;
        font-weight: 900;
        margin-right: 5px;
    }
    #price span.price {
        font-size: 35px;
    }
    .product-description .quantity-box {
        margin-top: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .quantity-box .option {
        margin-bottom: 0;
    }
    .quantity-box #add_to_cart .icon-basket {
        top: 2px;
        font-size: 22px;
    }
    .quantity-box a.link {
        position: absolute;
        right: 0;
    }
    .quantity-box input#quantity_wanted,
    .quantity-box .input-group-addon {
        background: rgba(0, 0, 0, 0);
        height: 38px;
        line-height: 38px;
        border-bottom: 0;
    }
    div#manufacturerLogo {
        position: absolute;
        left: 0;
        bottom: 0;
        max-width: 100px;
    }
}
#detailyProduktu {
    padding-top: $separator + 50px;
    outline: none;
    .productFeatures {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .productFeatures .item {
        margin-right: 50px;
        margin-bottom: 30px;
        min-width: 100px;
        &:last-child {
            margin-right: 0;
        }
    }
    h4 {
        font-family: $font2;
        color: var(--color-text2);
        font-weight: 100;
        font-size: 20px;
        text-transform: none;
        font-style: italic;
        padding: 0;
        margin-bottom: 10px;
        display: inline-block;
        white-space: nowrap;
    }
    div#short_description_block {
        margin-top: 20px;
        color: #000;
    }
    div#short_description_block #short_description_content {
        font-size: 16px;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: top;
        transform-origin: top;
        height: 0;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        &.active {
            visibility: visible;
            opacity: 1;
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
            height: auto;
        }
    }
    div#short_description_block #short_description_content p {
        max-width: unset;
    }
    #short_description_block h4 {
        cursor: pointer;
        &::after {
            content: "▼";
            position: relative;
            left: 5px;
            font-size: 13px;
            top: -2px;
        }
    }
}
.manufacturerInfo {
    padding-top: $separator/2;
    p {
        max-width: unset;
    }
    .content {
        margin-top: $separator;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .col {
        width: 50%;
        &:last-child {
            text-align: right;
            padding-left: 50px;
        }
    }
    .introImg {
        display: block;
        width: 100%;
        margin-top: 0;
        max-width: 1000px;
        margin: auto;
    }
    h2 {
        font-family: $font2;
        color: var(--color-text2);
        font-weight: 100;
        font-size: 30px;
        text-transform: none;
        padding: 0;
        margin-bottom: 10px;
    }
    .logo {
        max-width: 250px;
        margin-bottom: 30px;
    }
}
.blockproductscategory {
    padding-top: $separator;
    margin: auto;
    .owl-carousel {
        position: relative;
    }
    .owl-theme .owl-controls {
        display: block;
    }
}
#category {
    section.page-content,
    section.site-content {
        margin-top: $separator;
    }
    .row.master {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    section#center_column {
        width: 75%;
    }
    aside.content-aside.left {
        width: 30%;
        padding-right: 50px;
    }
}
.categoryHeader {
    margin-top: $headerHeight;
    position: relative;
    &::before {
        content: "";
        width: 70%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 2;
        left: 0;
        background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.9)), to(rgba(255, 255, 255, 0)));
        background: linear-gradient(to right, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
    }
    .content_scene_cat_bg {
        height: 300px;
        min-height: unset !important;
    }
    .innerContent.container {
        position: relative;
        z-index: 2;
    }
    .content_scene_cat_bg .innerContent .container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    h1 {
        font-family: $font2;
        color: var(--color-text2);
        font-weight: 100;
        font-size: 40px;
        text-transform: none;
        padding: 0;
        margin: 0;
    }
}
.content-aside.left {
    #layered_block_left #enabled_filters .layered_subtitle h3,
    #categories_block_left .title_block h3,
    #enabled_filters .layered_subtitle h3,
    .layered_subtitle_heading {
        font-family: $font2;
        font-weight: 100 !important;
        font-size: 25px !important;
        text-transform: none !important;
        font-style: italic;
        padding: 0;
        margin-bottom: 20px;
    }
    #categories_block_left ul,
    .layered_filter_ul {
        margin-bottom: 10px;
        li {
            margin-bottom: 25px;
        }
    }
    #categories_block_left ul {
        li {
            margin-left: 25px;
        }
    }
    .ui-slider-range.ui-widget-header.ui-corner-all {
        background-color: $color-base;
    }
    #layered_block_left .layered_filter_ul li a {
        color: rgb(0, 0, 0);
        border-bottom: none;
    }
    #layered_block_left .layered_filter_ul li {
        color: rgba(0, 0, 0, 0.6);
    }
    #layered_block_left li.nomargin > label {
        margin-left: 6px;
    }
    a.ui-slider-handle.ui-state-default.ui-corner-all {
        cursor: pointer;
    }
}
.pagination {
    text-align: center;
    li a,
    li span {
        background-color: rgba(0, 0, 0, 0) !important;
    }
    .active > span {
        color: $color-alt;
    }
}
#enabled_filters {
    .layered_subtitle {
        border-top: 1px solid $color-border;
        padding-top: 40px;
    }
}
.jms-megamenu {
    .dropdown-menu {
        top: $headerHeight - 1 !important;
        position: fixed !important;
        -webkit-transition: 0.1s ease-in;
        transition: 0.1s ease-in;
        border-top: 1px solid rgb(228, 228, 228) !important;
        top: 111px !important;
    }
    .open > .dropdown-menu:after {
        display: none;
    }
    .nav > li.open > a:after {
        bottom: -29px;
    }
    .nav > li > a > .caret {
        display: inline-block;
    }
    .dropdown-menu ul.mega-nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 20px 0;
    }
    .dropdown-menu ul.mega-nav img {
        display: block;
        margin: 0 auto 20px;
        max-height: 250px;
    }
    .mega-nav li > a {
        font-family: $font2;
        font-weight: 100 !important;
        font-size: 25px !important;
        text-transform: none !important;
        font-style: italic;
        padding: 0;
        text-align: center;
        &:hover {
            color: $color-base !important;
        }
    }
    li.menu-item {
        padding: 0;
    }
    .dropdown-menu li.menu-item {
        padding: 20px;
        border: 1px dashed transparent;
        &:hover {
            border: 1px dashed rgba(0, 0, 0, 0.2);
            -webkit-box-shadow: 0px 0px 150px 10px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 0px 150px 10px rgba(0, 0, 0, 0.15);
        }
    }
    .categoryIcon {
        position: absolute;
        right: 0;
        z-index: 1;
    }
}
